import { useCountryStore } from "../../data/repositories";
import { DEFAULT_COUNTRY } from "../../domain/constants";
import { Country, CountrySource, CountrySourceType } from "../../domain/view-models";

type UseCountryResult = {
  country: Country;
  source: CountrySourceType;
};

export const useCountry = (): UseCountryResult => {
  const { country, source } = useCountryStore();

  return {
    country: country ?? DEFAULT_COUNTRY,
    source: source ?? CountrySource.DEFAULT,
  };
};
